"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const models_1 = require("../models");
const react_components_1 = require("@cimpress/react-components");
const result_1 = require("./result");
const resultSourceTable_1 = require("./resultSourceTable");
const react_1 = require("react");
const auth_1 = require("../auth");
const react_router_1 = require("react-router");
const sourceListView_1 = require("./sourceListView");
const { Spinner } = react_components_1.shapes;
function updateDisplaySource(sources, source) {
    if (sources.includes(source))
        return sources.filter(i => i != source);
    const res = sources.slice(0);
    res.push(source);
    return res;
}
function Main() {
    const [terms, setTerms] = react_1.useState('');
    const [results, setResults] = react_1.useState([]);
    const [processing, setProcessing] = react_1.useState(false);
    const [processedTerms, setProcessedTerms] = react_1.useState('');
    const [displaySources, setDisplaySources] = react_1.useState([]);
    const [displayAllSources, setDisplayAllSources] = react_1.useState(true);
    const [everSearched, setEverSearched] = react_1.useState(false);
    const history = react_router_1.useHistory();
    const location = react_router_1.useLocation();
    react_1.useEffect(() => {
        const currentPath = location.pathname;
        const searchParams = new URLSearchParams(location.search);
        goSearch(searchParams.get('query'));
    }, [location]);
    function goSearch(value) {
        return __awaiter(this, void 0, void 0, function* () {
            if (value !== null && value.length > 0) {
                setProcessing(true);
                setTerms(value);
                const token = auth_1.default.getAccessToken();
                const results = yield models_1.search(value, token);
                setProcessing(false);
                setResults(results);
                setProcessedTerms(terms);
                setEverSearched(true);
            }
        });
    }
    function doSearch() {
        return __awaiter(this, void 0, void 0, function* () {
            history.push('/?query=' + encodeURIComponent(terms));
        });
    }
    var searchResultsToDisplay = [];
    var resultCount = 0;
    var shownResultCount = 0;
    if (!processing && everSearched) {
        var resultsToUse = results;
        if (displaySources.length > 0) {
            resultsToUse = results.filter(r => displaySources.includes(r.source));
        }
        searchResultsToDisplay = [].concat.apply([], resultsToUse.map(s => s.results.map(r => ({ url: r.url, title: r.title, excerpt: r.excerpt, score: r.score, source: s.source, path: [s.basePathEntry].concat(r.path), modifiedAt: r.modifiedAt }))));
        searchResultsToDisplay.sort((a, b) => b.score - a.score);
        shownResultCount = searchResultsToDisplay.length;
        resultCount = results.map(s => s.numberOfResults).reduce((sum, current) => sum + current, 0);
    }
    return (React.createElement("div", null,
        React.createElement("nav", { className: "navbar navbar-default" },
            React.createElement("div", { className: "navbar-brand", style: { marginLeft: '0' } },
                React.createElement("img", { src: "https://static.ux.cimpress.io/mcp-ux-css/1.2/release/assets/logo-dark.svg", alt: "Cimpress" }))),
        React.createElement("div", { className: "navbar-fluid " },
            React.createElement("nav", { className: "navbar navbar-block subtitle" },
                React.createElement("div", { className: "navbar-header" },
                    React.createElement("h2", null, "Cimpress Internal Search")))),
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "content-wrapper" },
                React.createElement("form", { onSubmit: e => { e.preventDefault(); doSearch(); } },
                    React.createElement(react_components_1.TextField, { name: "query", label: "Search Terms", value: terms, onChange: e => setTerms(e.target.value), rightAddon: React.createElement(react_components_1.Button, { type: "default" }, "Go") })),
                processing ? React.createElement(Spinner, null) : everSearched ?
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col col-sm-4 col-sm-push-8" },
                            React.createElement(resultSourceTable_1.ResultSourceTable, { results: results, sources: displaySources, allSources: displayAllSources, updateDisplaySource: (src) => setDisplaySources(sources => updateDisplaySource(sources, src)), updateAllSources: setDisplayAllSources })),
                        React.createElement("div", { className: "col col-sm-8 col-sm-pull-4" },
                            React.createElement("div", { className: "summary" }, `Displaying ${shownResultCount} of ${resultCount} results for "${processedTerms}"`),
                            searchResultsToDisplay.map((r, i) => React.createElement(result_1.Result, { key: `res${i}`, result: r }))))
                    : React.createElement(sourceListView_1.default, null)))));
}
exports.default = Main;
