"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_components_1 = require("@cimpress/react-components");
const react_1 = require("react");
const React = require("react");
const auth_1 = require("../auth");
const models_1 = require("../models");
function SourceListView() {
    const [sourceList, setSourceList] = react_1.useState(undefined);
    react_1.useEffect(() => {
        function setSources() {
            return __awaiter(this, void 0, void 0, function* () {
                const sources = yield (models_1.getSources(auth_1.default.getAccessToken()));
                setSourceList(sources);
            });
        }
        setSources();
    }, []);
    return sourceList === undefined ? React.createElement(React.Fragment, null) :
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col col-sm-6" },
                React.createElement(react_components_1.Card, { header: "Sources that we search" }, sourceList.activeSources.map(s => (React.createElement("div", { key: s.source },
                    React.createElement("a", { href: s.url }, s.source)))))),
            React.createElement("div", { className: "col col-sm-6" },
                React.createElement(react_components_1.Card, { header: "Sources that we do not currently search" }, sourceList.inactiveSources.map(s => (React.createElement("div", { key: s.source },
                    React.createElement("a", { href: s.url }, s.source)))))));
}
exports.default = SourceListView;
