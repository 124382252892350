"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ReactDOM = require("react-dom");
const React = require("react");
const app_1 = require("./app");
const react_components_1 = require("@cimpress/react-components");
window.addEventListener("load", () => {
    ReactDOM.render(React.createElement(react_components_1.CssLoader, null,
        React.createElement(app_1.default, null)), document.getElementById("entirepage"));
});
