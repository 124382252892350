"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_router_1 = require("react-router");
const history_1 = require("history");
const React = require("react");
const main_1 = require("./main");
// const history = createBrowserHistory();
function Root() {
    const history = history_1.createBrowserHistory();
    return React.createElement(react_router_1.Router, { history: history },
        React.createElement(react_router_1.Switch, null,
            React.createElement(react_router_1.Route, { path: "/", render: (props) => React.createElement(main_1.default, null) })));
    // }
}
exports.default = Root;
