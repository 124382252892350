"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResultSourceTable = void 0;
const React = require("react");
const react_components_1 = require("@cimpress/react-components");
function numberOrError(result) {
    if (result.error != null && result.error != "") {
        return "Error";
    }
    return result.numberOfResults.toString();
}
function ResultSourceTable({ results, sources, allSources, updateDisplaySource, updateAllSources }) {
    return React.createElement(react_components_1.Card, { header: "Narrow results to only:" },
        React.createElement(react_components_1.Checkbox, { checked: allSources, label: "Display All", onChange: e => updateAllSources(!allSources) }),
        results.map(r => React.createElement("div", { key: r.source },
            React.createElement(react_components_1.Checkbox, { checked: sources.includes(r.source), indeterminate: allSources, onChange: e => updateDisplaySource(r.source), label: React.createElement("span", null,
                    React.createElement("a", { href: r.url }, r.source),
                    ` (${numberOrError(r)})`) }))));
}
exports.ResultSourceTable = ResultSourceTable;
