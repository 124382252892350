"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const auth_1 = require("./auth");
const root_1 = require("./controls/root");
auth_1.default.on('tokenExpired', () => auth_1.default.login({ nextUri: window.location.pathname, forceLogin: true }));
class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = { authenticating: false, authenticationError: null };
        const basename = process.env.REACT_APP_ROUTER_BASENAME || '';
    }
    componentWillMount() {
        if (!auth_1.default.isLoggedIn()) {
            this.setState({ authenticating: true });
            console.log(window.location.pathname + window.location.search);
            auth_1.default
                .ensureAuthentication(window.location.pathname + window.location.search)
                .then(() => {
                this.setState({ authenticating: false });
            })
                .catch(err => {
                this.setState({ authenticationError: err });
            });
        }
    }
    render() {
        const { authenticating, authenticationError } = this.state;
        return (React.createElement("div", null, authenticating ? (React.createElement("h1", null, "Loading")) : auth_1.default.isLoggedIn() ? (React.createElement(root_1.default, null)) : (authenticationError ? (React.createElement("div", null,
            "Unexpected error encountered. ",
            authenticationError.message)) : null)));
    }
}
exports.default = App;
