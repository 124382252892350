"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CLIENT_ID = void 0;
const simple_auth_wrapper_1 = require("@cimpress/simple-auth-wrapper");
exports.CLIENT_ID = 'RrZTTXUzoxiJtLHf4WqVAOOck3smSIqD';
const auth = new simple_auth_wrapper_1.centralizedAuth({
    clientID: exports.CLIENT_ID,
    redirectRoute: '/'
});
exports.default = auth;
