"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSources = exports.search = void 0;
// const BASE_URL = "https://localhost:5001/";
// // OLD const BASE_URL = "https://cugp7xmi25.execute-api.us-east-1.amazonaws.com/Prod/";
const BASE_URL = "https://xfi1c4590l.execute-api.us-east-1.amazonaws.com/Prod/";
function search(terms, token) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = BASE_URL + "v0/search?q=" + encodeURIComponent(terms);
        const response = yield fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer " + token
            }
        });
        const result = yield response.json();
        return result;
    });
}
exports.search = search;
function getSources(token) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = BASE_URL + "v0/sources";
        const response = yield fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer " + token
            }
        });
        const result = yield response.json();
        return result;
    });
}
exports.getSources = getSources;
