"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Result = void 0;
const React = require("react");
const parse = require("html-react-parser");
function Result({ result }) {
    var _a;
    return (React.createElement("div", { className: "search-result" },
        React.createElement("ul", { className: "path" }, result.path.map((e, i) => (React.createElement("li", { className: "pathPart", key: "p" + i },
            React.createElement("a", { href: e.url }, e.text))))),
        React.createElement("div", { className: "result-title" },
            React.createElement("a", { href: result.url }, result.title)),
        React.createElement("div", { className: "snippet" }, parse((_a = result.excerpt) !== null && _a !== void 0 ? _a : "", {
            replace: domNode => {
                if (domNode.type == "text") {
                    return;
                }
                if (domNode.type == "tag" && domNode.name == "em") {
                    return React.createElement("span", { className: "match" }, domNode.children[0].data);
                }
                return React.createElement("span", null);
            }
        })),
        (result.modifiedAt.toString() > '0005') ? React.createElement("div", { className: "modified-date" }, result.modifiedAt) : React.createElement(React.Fragment, null)));
}
exports.Result = Result;
